form {
  margin-bottom: 90px;
  @media (min-width: 1200px) {
    margin-bottom: 130px;
  }

  .form-row {
    display: grid;
    gap: 20px;

    @media (min-width: 1200px) {
      .input-nome{
        grid-column: 1 / 4;
      }      
      .input-email {
        grid-column: 1 / 4;
      }
      .input-telefone{
        grid-column: 1 / 2;
      }
      .input-celular {
        grid-column: 2 / 4;
      }

      .input-mensagem {
        textarea {
          height: 170px;
        }
        grid-column: 1 / 4;
      }
    }
    label{margin-bottom: 15px; display: block;}
    input,
    textarea {
      &::placeholder {
        color: #bbbbbb;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
      }
      &:focus {
        outline: none;
      }
      // background-color: #f1f1f1;
      width: 100%;
      padding: 15px;
      border: none;
      border: 1px solid #B4B4B4;
      border-radius: 7px;
      padding: 15px;
      background-color: #FAFAFA;
      @media (min-width: 1200px) {
        font-size: 18px;
        line-height: 34px;
      }
    }
  }
  .form-row--button {
    cursor: pointer;
    background-color: #707070;
    color: white;
    font-weight: bold;
    font-size: 19px;
    line-height: 33px;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    width: 100%;
    padding: 8px 20px;
    text-align: center;
    @media (min-width: 1200px) {
      width: 249px;
      float: right;
    }
  }
}


.leganda { position: absolute;}