@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,700;1,400&display=swap');
*,::after,::before{-webkit-box-sizing:border-box;box-sizing:border-box}blockquote,body,dd,dl,figcaption,figure,h1,h2,h3,h4,h5,h6,li,ol,p,ul{margin:0;padding:0;font-family: 'Montserrat', sans-serif;}
.row {max-width: 1200px;margin: 0 auto; padding: 0 15px;display: flex;}
@media(min-width:1200px){
	.row {padding: 0 30px;}
}
body{overflow-x: hidden;}
.d-none {display: none !important;}
@media (min-width: 1200px) {
  .d-xl-block {display: block !important;}
  .d-xl-none {display: none !important;}
}
img{max-width: 100%;}
h4{font-weight: 300;font-size: 33px;color:#BD9E5E; text-transform: uppercase;}
@media(min-width:1200px){
	h4{font-size: 50px;}
}
h4.line_decoration{position: relative;}
h4.line_decoration.left:before{content: ''; position: absolute; top: 50%; left: calc(-100vw - 60px); width: 100vw; height: 2px; background-color: #BD9E5E; }
h4.line_decoration.right:after{content: ''; position: absolute; top: 50%; transform: translateX(60px); width: 100vw; height: 2px; background-color: #BD9E5E; }

.description h4{padding-bottom:30px;font-size: 28px;}
.description p{font-size: 16px;font-weight: 300;color:#818181;text-transform: uppercase;line-height: 150%;}
.left .description p{text-align: right;}
.left .row{display: flex; flex-direction: column; align-items: flex-end;}
.right .description, .left .description{padding: 57px 0 67px;}

@media(min-width:1200px){
	.description p{font-size: 35px;}
	.description h4{padding-bottom:30px;font-size: 70px;}
}
.rec.rec-slider-container{margin: 0;}
.rec.rec-carousel-wrapper{position: relative;}
.rec-carousel-item .slide_item{position: relative;}
.rec-carousel-item .slide_item p{width: fit-content; position: absolute;padding: 5px 10px; color: white; right: 10px; bottom: 14px; font-size: 14px; font-weight: 400; background-color: #BD9E5E;}
.rec-carousel-item .slide_item,
.rec-carousel-item-visible,
.rec.rec-slider{outline: none;cursor: pointer;}

/* arrow */
.rec.rec-carousel button, 	
.rec.rec-carousel button:hover,
.rec.rec-carousel button:focus{box-shadow: none;background-color: transparent;color:#BD9E5E}

.slide .rec.rec-pagination{margin: 0 auto;display: flex;align-items: center;justify-content: center;} 
.slide .rec.rec-pagination button, 
.rec.rec-pagination button:hover, 
.rec.rec-pagination button:focus {border-radius: 50px;border: 1px solid #BD9E5E ;}
.slide .rec.rec-pagination button{background: transparent;}
.slide .rec.rec-pagination button.rec-dot_active{background: #bd9e5e;}
.slide .rec.rec-pagination button:hover{background: #bd9e5e;border-radius: 50px;}
.slide .rec.rec-pagination button.rec-dot_active {opacity: 1;background-color: #BD9E5E;border-radius: 50px;}
/* dots */
.rec.rec-pagination{margin: 0 92px 0 0;position: absolute;bottom: 30px;} 
.rec.rec-pagination button, 
.rec.rec-pagination button:hover,
.rec.rec-pagination button:focus{border: none;border-radius: 0;box-shadow: none;opacity: .5;background-color: #777567;height: 15px;width: 15px;}
/* dots item active */
.rec.rec-pagination button.rec-dot_active{opacity: 1;background-color: #BD9E5E} 
.line{width: 100%;height: 2px; background-color: #BD9E5E;}
/* NAVBAR */
header{z-index: 999;  position: fixed; width: 100%; background-color:#BD9E5E ; box-shadow:0 3px 9px #00000017 ; height: 80px; display: flex;align-items: center;}
.navbar{display: flex;align-items: center;}
.navbar .button_navbar{background-color: #707070; color:white; border-radius: 10px; padding: 10px 30px;font-size: 20px;}
.navbar .links_navbar li{cursor: pointer;list-style: none;font-weight: bold; font-size: 18px;}
.navbar ul{position: relative;}
.navbar .rede_social{background-color: #818181; border-radius: 10px; padding: 10px 15px;display: flex; align-items: center; justify-content: center;}
.navbar .rede_social a:first-child{margin-right: 10px;}
@media(max-width:1200px){
	.navbar.desktop{display: none;}
	.navbar .header_navbar{display: flex;width: 100%;justify-content: space-between;align-items: center;position: fixed; padding: 0 22px;}
	.navbar .links{transform: translateX(100%);transition: all .3s;position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9999;background: white;display: flex;align-items: center;justify-content: center;flex-direction: column;}
	.navbar .links.active{transform: translateX(0%);transition: all .3s;}
	.navbar .links_header{width: 100%;justify-content: space-between;display: flex;position: fixed;top: 10px;padding: 0 22px;}
	.navbar .links_navbar li{margin-bottom: 30px;}
}
@media(min-width:1200px){
	.navbar.mobile{display: none;}
	.navbar .links_navbar{margin-left: 76px; display: flex;align-items: center;}
	.navbar .links_navbar li{margin-right: 20px; border-bottom: 2px solid transparent; position: relative;color:white;}
	.navbar .links_navbar li:after{content: ''; position: absolute; left: 0; bottom: -3px; width: 0%; height: 3px; background-color: #fff; transition: all .8s;}
	.navbar .links_navbar li:hover:after{ width: 100%; background-color: #fff; transition: all .5s;}
	.navbar .links_navbar li:last-child:after{ content: none;}
	.navbar .links_navbar li.button_navbar{ display: flex; align-items: center; justify-content: center; margin-right: 0;background-color: #707070; color:white; width: 213px; height: 40px; text-align: center;}
	.navbar .links_navbar li.button_navbar:after{ content: none;}
	.navbar .rede_social{border-radius: 0 0 10px 10px; padding: 10px 10px 7px 10px; position: absolute; bottom: -76px; right: 30px;}
}

.zoomGallery{position: fixed;top: 0;left: 0;width: 100vw;height: 100vh;background: #000000fa;z-index: 1000; display: flex; align-items: center; justify-content: center;}
.zoomGallery .close{z-index: 1001; cursor: pointer; font-size: 40px;padding: 5px 10px;position: absolute;right: 0;top: 0;color: white;}
.zoomGallery img{max-width: 100%;}
.zoomGallery .slide_item{margin-right: 0 !important;}
.zoomGallery .rec.rec-carousel{max-height: initial;}
.box_9 .slide .zoomGallery .rec.rec-pagination{display: block;bottom: -20px;}
.viewGallery{display: flex;align-items: center; justify-content: center; position: relative; display: flex; width: 100vw;height: 90vh;background-color: transparent;}
@media(min-width:1200px){
	.viewGallery{width: 70vw;}
}
@media(min-width:1024px) and(max-width:1200px){
	.viewGallery{align-items: initial; margin-top: 25%;}
}

.box_1{padding-top: 80px;}
.box_1 img{width: 100%;}

.box_1_1 {margin: 40px 0 57px 0;}
.box_1_1 .slide{width: 100%;}
.box_1_1 .slide .rec.rec-pagination{display: none;}
.box_1_1 .slide img{padding: 0 10px;}

.box_2{background-color: #BD9E5E;}
.box_2 .row{justify-content: space-between;background-color: white; position: relative;flex-direction: column;}
.box_2 .column_1 p{font-size: 30px;font-weight: 300;color:#818181;text-transform: uppercase;padding: 15px 0;}
.box_2 .column_2 p{font-size: 20px;font-weight: 500;color:#818181;line-height: 35px;}
@media(max-width:1199px){
	.box_2  p {font-size: 16px; max-width: 650px;}
}
@media(min-width:1200px){
	.box_2 .column_1{position: relative;}
	.box_2 .column_1 h4{font-size: 73px;}
	.box_2 .row{flex-direction: row;align-items: center;}
	.box_2 .column_1:after{position: absolute;content: '';width: 2px;height: 90%;background: #bd9e5e;top: 5%;right: -104px;}
	.box_2 .column_2 {position: relative; right: 60px;}
	.box_2 .column_1 p.description{padding: 0;max-width: 500px; padding-top: 10px;}
}
@media(min-width:1300px){
	.box_2 .row:after{content: '';width: 100px;height: 100%;background-color: white;left: -100px;position: absolute;}
	.box_2 .row:before{content: '';width: 100px;height: 100%;background-color: white;right: -100px;position: absolute;}
}

.box_3 .itens{flex-wrap: wrap; margin: 110px auto;}
.box_3 .item {display: flex; align-items: center; list-style: none;width: 100%;margin-bottom: 50px;}
.box_3 .item p{color:#BD9E5E; color:15px}
.box_3 .item img{padding-left: 15px;width: 70px;margin-right: 45px;}
@media(min-width:768px){
	.box_3 .item {width: 50%; margin: 30px auto;}
}
@media(min-width:1200px){
	.box_3 .item {width: 33%;}
}

.box_4{position: relative;margin-bottom: -5px;overflow-x: hidden;}
.box_4 img{min-height: 300px;}
.box_4 .content{position: absolute; top: 50%;transform: translateY(-50%);}
.box_4 .content h4{font-size: 35px;}
@media(min-width:1500px){
	.box_4 .content h4{font-size: 45px;}
}
.box_4 h4.line_decoration.right:after,.box_4 h4.line_decoration.right:after {content: none;}
// .box_4 h4.line_decoration.right:after{transform: translateX(250px);}
// .box_4 h4.line_decoration.right:before {content: '';position: absolute;top: calc(50% - 20px);transform: translateX(700px);width: 100vw;height: 2px;background-color: #BD9E5E;}
// @media(max-width:1300px){
// .box_4 h4.line_decoration.right:before, .box_4 h4.line_decoration.right:after{content: none;}
// }

// .box_5{margin-top: 40px; max-width: 1170px; margin: 0 auto;}
.box_5 {position: relative;}
.box_5 img{max-width: initial; width: 100%;}

.box_6{margin: 40px 0 140px 0;}
.box_6 .rec.rec-pagination {
    margin: 0 auto 0 auto;
    position: absolute;
    bottom: -30px;
    z-index: 1;
}

.box_6 .row{padding: 0 !important;}

.box_6_1 {position: relative;}
.box_6_1 .blackground{position: relative;top: 170px;margin-top: -170px;z-index: -2;overflow: hidden;height: 250px;width: 200vw;}
.box_6_1 .row{display: flex; align-items: center;}
.box_6_1 .row .sustentabilidade{position: relative; top: -40px;}
.box_6_1 .row .sustentabilidade:after{background-color: #BD9E5E;position: absolute;top: -20px;right: 0px;content: '';width: 100vw;height: calc(100% + 40px);z-index: -1;}
.box_6_1 .row .sustentabilidade img{max-width: 83%;position: relative; top: -10px;}
.box_6_1 .row h4{font-size: 33px; font-weight: 300;}
.box_6_1 .row p{font-size: 20px; line-height: 30px; font-weight: 500;color:#818181; margin-top: 20px;padding-bottom: 50px;}
@media(min-width:1200px){
	.box_6_1 .row .sustentabilidade{right: 66px;}
	.box_6_1 .row .sustentabilidade{position: relative; top: 0; z-index: 2;}
	.box_6_1 .row .sustentabilidade:after{width: 100vw;transform: translateX(-88px);}
	.box_6_1 .row h4{padding-left: 30px;}
	.box_6_1 .row p{padding-left: 30px;min-width: 430px;padding-bottom: 0;min-width: 430px;}
}

.box_7,.box_8, .box_8_1 { position: relative;}
.box_7,.box_8, .box_8_1{overflow-x: hidden;}
@media(max-width:1199px){
	.box_7 h4.line_decoration.right:after {transform: translateX(16px);}
}
@media(max-width:1199px){
	.box_8 .description {width: 100%;}
	.box_8 .description *{text-align: right;}
	.box_8 h4.line_decoration.left:before {left: -60px;width: calc(100vw - 274px);}
}

.box_9 h4{padding: 70px 0 35px 0;}
.box_9 .content-img{background-image: url('./images/background_6_1.webp');}
.box_9 .slide{width: 100%;padding: 10px 0;}
.box_9 .slide img{width: 100%;max-width: initial;padding: 10px;}
.box_9 .slide .rec.rec-pagination{display: none;}

.box_10 {position: relative;}
.box_10 .rec.rec-pagination{position: relative;top: 50px;}
.box_10 {margin-bottom: 90px;overflow: hidden;}
.box_10 h4{padding: 100px 0 50px 0}
.box_10 h4.line_decoration.right:after {content: '';position: absolute;top: 50%;transform: translateX(200px);width: 100vw;height: 2px;background-color: #BD9E5E;}

@media(min-width:650px) and (max-width:1200px){
	.box_10 h4 {padding: 100px 200px 50px 0; max-width: 700px;}
}
@media(min-width:1200px){
	.box_10 {overflow: initial;}
	.box_10 h4{padding: 95px 0 115px 0;}
	.box_10:after {content: '';position: absolute;top:0;left: 0;width: 70px;height: 100%;background-color: #BD9E5E;}
	.box_10:before {content: '';position: absolute;top:0;right: 0;width: 70px;height: 100%;background-color: #BD9E5E;}
}

.box_11 .slide .rec-arrow-left, .box_11 .slide .rec-arrow-right{display: none;}
.box_11 .slide .rec.rec-pagination{position: relative; top: 25px;}
.box_11 .slide {flex-wrap: wrap;margin: 110px auto;}
.box_11 .slide .item{padding: 10px;}
.box_11 .slide .item *{text-transform: uppercase;}
.box_11 .slide .item h5{color: #BD9E5E;}
.box_11 .slide .item p{color: #707070;}
@media(max-width:1200px){
	.box_11  .line_decoration{overflow-x: hidden;}
	.box_11  h4.line_decoration.left::before {left: initial; right: calc(-87vw);}
	.box_11 .slide .item h5{font-size: 18px;padding-bottom: 10px;}
	.box_11 .slide .item p{font-size: 16px;}
}

.box_12{overflow-x: hidden;}
.box_12 H4{padding: 0 0 65px 0;}
@media(max-width:1199px){
	.box_12 h4.line_decoration.right:after{transform: translateX(40px);}
}

.box_13 {padding: 50px 0}
.box_13 .row.content{flex-direction: column;}
.box_13 .column_1 {padding: 52px 74px 45px 0;position: relative;}
.box_13 .column_1 h4{color:white; background-color: black;padding: 10px;}
.box_13 .column_2 img{max-width: 100%;}
.box_13 .cards {display: flex; flex-wrap: wrap; justify-content: center; align-items: flex-start;margin-top: 130px;position: relative;}
.box_13 .cards .item{margin-bottom: 30px;max-width: 100%;max-width: 338px;padding: 0 10px;}
.box_13 .cards .item h5{color:#406DAB; font-size: 22px; font-weight: bold;margin: 31px 0 18px 0;}
.box_13 .cards .item p{color:#707070; font-size: 22px; font-weight: 300;}
.box_13 .form-content{width: 100%;margin-top: 100px;margin-bottom: 0;}
@media(max-width:1199px){
	.box_13 .column_1 {padding: 0;margin-bottom: 30px;}
	.box_13 .column_1:after {right: -20px;}
	.box_13 h4.line_decoration.left:before{left: calc(-100vw - 30px);}

	.box_3 .itens { margin: 30px auto;}
}
@media(min-width:1200px){
	.box_13 {padding: 95px 0 60px 0;}
	.box_13 .column_1 h4{background-color: transparent;padding: 0;}
	.box_13 .column_1:after {background-color: #000;position: absolute;top: -20px;right: 26px;content: '';width: 100vw;height: calc(100% + 40px);z-index: -1;}
	.box_13 .row.content{flex-direction: row;}
	.box_13 .column_2 img{padding-left: 66px;}
	.box_13 .cards {justify-content: space-between;}
	.box_13 .cards::after{background-color: #406DAB;position: absolute;top: 338px;right: 30px;content: '';width: 100vw;height: 6px;z-index: -1;}
	.box_13 .cards .item img{max-width: initial;}
	.box_13 .cards .item{margin-bottom:0;}
}

.box_14 .line {margin: 0 0 56px 0;}
.box_14 h3{text-align: center; padding-bottom: 50px; color:#BD9E5E;}
.box_14 h3 a{text-decoration: none; color:#818181; font-weight: lighter;}
.box_14 .row{flex-direction: column;}
.box_14 .row .politica{font-weight: bold; font-size: 22px; background-color: #BD9E5E; color:white; max-width: 405px; margin: 0 auto; padding: 8px 18px; text-decoration: none;border-radius: 13px;}
.box_14 .row p{text-align: center; text-transform: uppercase; text-decoration: none; font-weight: bold; color:#818181; font-size: 24px;margin-top: 53px;}
.box_14 .row .numbers{margin: 20px auto 57px auto; display: flex; justify-content: space-between; align-items: center;width: 100%; max-width: 500px;}
.box_14 .row .numbers a, .box_14 .row .numbers span{text-decoration: none; font-weight: bold; color:#818181; font-size: 28px;}
.box_14 .row .numbers span{font-weight: 300;}
.box_14 .row .info{margin: 0 auto; display: flex; justify-content: center; align-items: center; width: 100%; max-width: 781px;}
@media(max-width:1199px){
	.box_14 .row p{text-align: center;}
	.box_14 .row .numbers, .box_14 .row .info{flex-direction: column;}
	.box_14 .row .numbers,.box_14 .row .info {max-width: initial;}
	.box_14 .row .numbers span{display: none;}
	.box_14 .row .numbers a{font-size: 30px;margin-bottom: 15px;display: block;}
	.box_14 .row .info{justify-content: center;}
	.box_14 .row .info .item{width: 75%; margin: 0 auto; display: flex;justify-content: center;}
}

.whatsapp{z-index: 3; position: fixed;bottom: 15px;right: 15px;width: 60px;}
.whatsapp a{display: block;}

footer{background-color: #BD9E5E;margin-top: 35px;}
footer .row{padding: 40px 0;}
footer .row p{color:white; text-align: center;font-weight: 500;font-size: 12px; line-height: 150%;}
@media(max-width:1199px){
	footer .row p{padding: 0 10px;}
}

.legenda { position: absolute; padding: 5px 20px; background-color: #BD9E5E; color: white;}
.legenda.right { right: 0; top: 94%}

.privacy{z-index: 2; display: flex; align-items: center; justify-content: space-between; position: fixed; bottom: 0; left: 0;width: 100%; height: 60px; background-color: #d5d5d5;}
.privacy.active{visibility: hidden; opacity: 0;}
.privacy .row{width: 100%; max-width: 1200px; margin: 0 auto; align-items: center; justify-content: space-between;}
.privacy .frase-aviso{ font-size: 12px;}
.privacy .buttons{display: flex; align-items: center; justify-content: space-between;}
.privacy .button{cursor: pointer; border-radius: 5px; padding: 10px 15px; margin: 5px; color: white;}
.privacy .button.aceitar{background-color: rgb(0, 161, 0); font-size: 13px;}
.privacy .button.saiba-mais{background-color: #a7a7a7;font-size: 12px;}
@media(max-width:1199px){
	.privacy {height: initial;padding: 10px 0 5px 0;}
	.privacy .row{flex-direction: column;}
	.privacy .buttons{width: 100%;margin-top: 5px;}
}

iframe{max-width: 100%; width: 100vw; height: 100vh;}

.privacy-page{max-width: 1200px; margin: 0 auto; padding: 30px;}

.banner_agracimento{margin-bottom: 15px; background-position: center; background-repeat: no-repeat; background-size: cover; height: 650px; 
	width: 100%; position: relative; background-image: url("./images/banner_agradecimento.jpg");
}
.banner_agracimento .content_description{width: 90%; max-width: 750px; position: absolute; top: 50%; left: 50%;
transform: translate(-50%, -50%); background-color: #000000cc; padding: 15px;
}
.banner_agracimento .content_description h3{font-size: 22px; margin-bottom: 15px; text-transform: uppercase; text-align: center; color:#bd9e5e}
.banner_agracimento .content_description p{font-weight: lighter; font-size: 16px; line-height: 150%; text-align: center; color:white}

@media(min-width:1200px){
	.banner_agracimento .content_description h3{font-size: 30px;}
	.banner_agracimento .content_description p{font-size: 22px;}
}